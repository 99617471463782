import React from 'react';
import './PageError.scss';
import { Link } from 'gatsby';

export default function PageError() {
    return (
        <section className="section-page-error">
            <div className="content">
                <h1>
                    {' '}
                    <span role="img" aria-label="sad">
                        😯
                    </span>{' '}
                    404
                </h1>
                <span>Page can’t be found.</span>
                <p>
                    Return
                    <Link to="/" className="button">
                        Home
                    </Link>
                </p>
            </div>
        </section>
    );
}
