import React from "react";
import Layout from "component/Layout/Layout";
import PageError from "component/PageError/PageError";
import SidebarLeft from "component/Sidebar/SidebarLeft";
import SidebarRight from "component/Sidebar/SidebarRight";
import Seo from "component/seo";

export default function NotFoundPage() {
  return (
    <Layout>
      <Seo titleSample={true} title="Không tìm thấy trang 😢" />
      <SidebarLeft />
      <PageError />
      <SidebarRight />
    </Layout>
  );
}
